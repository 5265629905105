import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const MXpage = () => (
  <Layout>
    <SEO title="MX Product" keywords={[`Nesting`, `Production Environment`, `react`]} />
    <div className="BlueGradient">
      <div className="--headerClear"></div>
      <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation ">
        <h2><span>MxManager</span> is the hub for Mx Modules</h2>
        <p>Tap into real-time information on your shop floor and enjoy the benefits of increased production. </p>
      </section>
    </div>
    <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>Product Overview</h5>
        <p className="DarkP">Let’s face it.  Planned production is easy to manage. It’s the last minute changes and unplanned work that reduces productivity. With MxManager accurately capturing real-time information from the shop floor, you have the knowledge you need to successfully manage both planned and unplanned work. From expediting part handling with MxStation to automating shop floor data collection with MxMRP,  MxManager gives you the information needed to control and maximize productivity.</p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --grid --sectionPace">
      <div className="--centerMargin" id="productChip">
        <Link to="/mxmrp"> 
          <div id="ProductChip">
            <h4>MxMRP</h4>
            <hr></hr>
            <p>Real-Time Monitoring</p>
          </div>
        </Link>
      </div>
      <article  id="--MxOverview" className="--centerTxt --maxwidthM">
        <h2 className="DarkH">MxMRP keeps all of the data together</h2>
        <p className="DarkP">No more error-prone and time consuming manual reporting. Get an accurate and fast look at all of you completed jobs</p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --grid">
      <div className="--centerMargin" id="productChip">
        <Link to="/mxstation"> 
          <div id="ProductChip">
            <h4>MxStation</h4>
            <hr></hr>
            <p>Smarter Labels & Tickets</p>
          </div>
        </Link>
      </div>
      <article  id="--MxOverview" className="--centerTxt --maxwidthM">
        <h2 className="DarkH">Downstream control with Mx Station</h2>
        <p className="DarkP">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited.</p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --grid --sectionPace">
      <div className="--centerMargin" id="productChip">
        <Link to="/mxpallet"> 
          <div id="ProductChip">
            <h4>MxPallet</h4>
            <hr></hr>
            <p>Pinpoint Documentation</p>
          </div>
        </Link>
      </div>
      <article  id="--MxOverview" className="--centerTxt --maxwidthM">
        <h2 className="DarkH">Track Cut Sheets that need to be de-nested</h2>
        <p className="DarkP">MxPallet tracks your sheet and part information, records it, and assigns unique pallet identification numbers that are traceable. </p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --grid">
      <div className="--centerMargin" id="productChip">
        <Link to="/mxticket"> 
            <div id="ProductChip">
              <h4>MxTicket</h4>
              <hr></hr>
              <p>Smarter Labels & Tickets</p>
            </div>
          </Link>
      </div>
      <article  id="--MxOverview" className="--centerTxt --maxwidthM">
        <h2 className="DarkH">Mx Ticket keeps you in the loop</h2>
        <p className="DarkP">Tickets are automatically created when needed, ensuring that up-to-date information makes its way to the shop floor.</p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --grid --sectionPace">
      <div className="--centerMargin" id="productChip">
          <Link to="/mxreport"> 
            <div id="ProductChip">
              <h4>MxReport</h4>
              <hr></hr>
              <p>Pinpoint Documentation</p>
            </div>
          </Link>
      </div>
      <article  id="--MxOverview" className="--centerTxt --maxwidthM">
        <h2 className="DarkH">MxReport color codes what you need</h2>
        <p className="DarkP">Customize your reports to fit your needs. MxReport supports easy bar coded information.</p>
      </article>
    </section>
  </Layout>
)

export default MXpage
